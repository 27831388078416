import { Routes, Route, Link, Outlet, Navigate, useLocation} from 'react-router-dom'
import ManageRole from '../Administration/ManageRole'
import RolePermission from '../Administration/ManageRole/RolePermission'
import EmployeeList from '../EmployeeManagement/EmployeeList'
import EmployeeList2 from '../EmployeeManagement/EmployeeList/retail'
import EmployeeAdd from '../EmployeeManagement/EmployeeAdd/Custom'
import EmployeeAdd2 from '../EmployeeManagement/EmployeeAdd/Retail'
import EmployeeEdit from '../EmployeeManagement/EmployeeEdit/Custom'
import EmployeeShow from '../EmployeeManagement/EmployeeShow/Custom'
import EmployeeShow2 from '../EmployeeManagement/EmployeeShow/Retail'
import EmployeeEdit2 from '../EmployeeManagement/EmployeeEdit/Retail'
import CandidateList from '../Candidates/CandidateList'
import CandidateAdd from '../Candidates/CandidateAdd'
import CandidateEdit from '../Candidates/CandidateEdit'
import CandidatePromote from '../Candidates/CandidatePromote'
import Department from '../EmployeeManagement/Department'
import Designation from '../EmployeeManagement/Designation'
import Team from '../Setup/Employee/Team'
import JobLevel from '../Setup/Employee/JobLevel'
import Branch from '../EmployeeManagement/Branch'
import Allowance from '../Payroll/Allowance'
import Deduction from '../Payroll/Deduction'
import MonthlyPayGrade from '../Payroll/MonthlyPayGrade'
import CustomPayGrade from '../Payroll/CustomPayGrade'
import Report from '../Payroll/Report'
import PayrollRange from '../Setup/Payroll/PayrollRange'
import GovernmentContribution from '../Setup/GovernmentContribution'
import TaxRuleSetup from '../Setup/TaxRuleSetup'
import WithholdingTaxSetup from '../Setup/Payroll/WithholdingTaxSetup'
import GeneratePayroll from '../Payroll/GeneratePayroll'
import Adjustment from '../Payroll/Adjustment'
import HolidayManagement from '../Leaves/HolidayManagement/Custom'
import HolidayManagement2 from '../Leaves/HolidayManagement/Retail'
import HolidayType from '../Leaves/HolidayType'
import LeaveConfiguration from '../Leaves/LeaveConfiguration'
import LeaveApplication from '../Leaves/LeaveApplication'
import LeaveApplication2 from '../Leaves/LeaveApplication/Retail'
import LeaveApplication3 from '../Leaves/LeaveApplication/Architecture'
import LeaveApproval from '../Leaves/LeaveApproval/Custom'
import LeaveApproval2 from '../Leaves/LeaveApproval/Retail'
import PayrollList from '../Payroll/PayrollList'
import PayrollList2 from '../Payroll/PayrollList/Retail'
import PayrollList3 from '../Payroll/PayrollList/SecurityAgency'
import PayrollListStandard from '../Payroll/PayrollList/Standard'
import Payslip from '../Payroll/Payslip'
import OfficialBusiness from '../Attendance/OfficialBusiness/Bpo'
import OfficialBusiness2 from '../Attendance/OfficialBusiness/Construction'
import OfficialBusiness3 from '../Attendance/OfficialBusiness/SecurityAgency'
import OfficialBusiness4 from '../Attendance/OfficialBusiness/Retail'
import OfficialBusiness5 from '../Attendance/OfficialBusiness/Architecture'
import Undertime from '../Attendance/Undertime/Custom'
import Undertime2 from '../Attendance/Undertime/Retail'
import COA from '../Attendance/COA/Bpo'
import COA2 from '../Attendance/COA/Construction'
import COA3 from '../Attendance/COA/SecurityAgency'
import COA4 from '../Attendance/COA/Retail'
import COA5 from '../Attendance/COA/Architecture'
import Overtime from '../Attendance/Overtime/Bpo'
import Overtime2 from '../Attendance/Overtime/Construction'
import Overtime3 from '../Attendance/Overtime/SecurityAgency'
import Overtime4 from '../Attendance/Overtime/Retail'
import Overtime5 from '../Attendance/Overtime/Architecture'
import ChangeSchedule from '../Attendance/ShiftSwap'
import Dashboard from '../Dashboard'
import Attendance from '../Attendance'
import WorkShift from '../Attendance/WorkShift'
import NoticeBoard from '../Administration/NoticeBoard'
import AllowedDevice from '../Administration/AllowedDevice'
import AllowedDevice2 from '../Administration/AllowedDevice/Standard'
import Login from '../Login'
import Kiosk from '../Kiosk/Bpo'
import PayrollComputation from '../Setup/Payroll/PayrollComputation'
import LateComputation from '../Setup/Payroll/LateComputation'
import GeneralSetup from '../Setup/General'
import WorkSite from '../EmployeeManagement/WorkSite/Custom'
import WorkSite2 from '../EmployeeManagement/WorkSite/Retail'
import HourShift from '../Setup/Employee/HourShift'
import HourShift2 from '../Setup/Employee/HourShift/Retail'
import Scheduling from '../Scheduling'
import Profile from '../Profile'
import GlobalSearch from '../GlobalSearch'
import ShiftName from '../Setup/Employee/ShiftName'
import ChangePassword from '../ChangePassword'
import Notfound from '../Error/404'
import OrganizationChart from '../OrganizationalChart'
import ActivityLog from '../Administration/ActivityLog'
import AttendanceSettings from '../Setup/Attendance/AttendanceSettings'
import AttendanceReport2 from "../Attendance/AttendanceReport/SecurityAgency";
import React from "react";
import AttendanceReport3 from "../Attendance/AttendanceReport/Construction";
import {Button} from "react-bootstrap";

const RoleAccess = () => {
    const path_name = useLocation().pathname;
    const modules = JSON.parse(localStorage.getItem('modules') ?? "");
    const token = localStorage.getItem('jwt')
    let hasAccess = false;
    let routesExistOnDatabase = false;
    console.log("token ", token);
    if (token) {
      modules.map((module : any) => {
        module.sidenav_sublinks.map((subMenu : any) => {
          if (subMenu.menu_url === path_name) {
            routesExistOnDatabase = true
            if (subMenu.hasPermission) {
              hasAccess = true;
            }
          }
        })
      })
    } else {
      return <Navigate to="/login" replace/>;
    }
    console.log("routesExistOnDatabase ", routesExistOnDatabase)
    if (routesExistOnDatabase) {
      return hasAccess
        ? <Outlet/>
        : <Navigate to="/home" replace/>;
    } else {
      return <Navigate to="/home" replace/>;
    }
};

const RedirectUnAuthorized = ({ to } : any) => {
        window.location.replace(to);
    return null;
};


const AppRoutes = () => {
  return (
    <Routes>

      <Route path="/" element={<Link to="/login" />} />
      <Route path='/login' element={<Login />} />
      <Route path='/kiosk' element={<Kiosk />} />
        <Route path='/home' element={<Dashboard />} />
        <Route path="*" element={<RedirectUnAuthorized to="/unauthorized" />} />

      {/*<Route element={<RoleAccess />}>*/}
        <Route path='/administration/notice' element={<NoticeBoard/>} />
        <Route path='/administration/manage-role' element={<ManageRole />} />
        <Route path='/administration/role-permission' element={<RolePermission />} />

        <Route path='/administration/change-password' element={<ChangePassword />} />

        <Route path='/employeeManagement/department' element={<Department />} />
        <Route path='/employeeManagement/designation' element={<Designation />} />
        <Route path='/employeeManagement/job-level' element={<JobLevel />} />
        <Route path='/employeeManagement/branch' element={<Branch />} />
        <Route path='/setup/employee/team' element={<Team />} />
        {/*<Route path='/employeeManagement/branch' element={<ManageRole />} />*/}

        <Route path='/leaves/holiday-type' element={<HolidayType />} />
        <Route path='/leaves/leave-configuration' element={<LeaveConfiguration />} />

        <Route path='/leaves/leave-report' element={<HolidayManagement />} />
        <Route path='/attendance/attendance-report' element={<Attendance />} />
        <Route path='/attendance/work-shift' element={<WorkShift />} />
        <Route path='/attendance/shift-swap' element={<ChangeSchedule />} />
        <Route path='/payroll/payslips' element={<Payslip />} />
        <Route path='/profile' element={<Profile />} />
        <Route path={'/employeeManagement/candidate-list'} element={<CandidateList/>}/>
        <Route path={'/employeeManagement/add-candidate'} element={<CandidateAdd/>}/>
          <Route path={'/employeeManagement/edit-candidate/:id'} element={<CandidateEdit/>}/>
          <Route path='/employeeManagement/promote-candidate/:id' element={<CandidatePromote />} />
        <Route path={'/setup/payroll/withholding-tax-setup'} element={<WithholdingTaxSetup/>} />
        <Route path={'/activity-log'} element={<ActivityLog/>} />
        <Route path={'/setup/attendance/attendance-settings'} element={<AttendanceSettings/>} />
          {
              (() => {
                  if (localStorage.getItem('clientProfile') == "bpo") {
                      return (
                          <>
                              <Route path='/attendance/overtime' element={<Overtime />} />
                              <Route path='/attendance/coa' element={<COA />} />
                              <Route path='/attendance/official-business' element={<OfficialBusiness />} />
                              <Route path='/employeeManagement/employee-list' element={<EmployeeList />} />
                              <Route path='/setup/employee/hours-shift' element={<HourShift />} />
                              <Route path='/leaves/leave-application' element={<LeaveApplication />} />
                              <Route path='/employeeManagement/edit-employee/:id' element={<EmployeeEdit />} />
                              <Route path='/employeeManagement/add-employee' element={<EmployeeAdd />} />
                              <Route path='/employeeManagement/show-employee/:id' element={<EmployeeShow />} />
                              <Route path='/setup/employee/work-site' element={<WorkSite />} />
                              <Route path='/leaves/holiday-management' element={<HolidayManagement />} />
                              <Route path='/leaves/leave-approval' element={<LeaveApproval />} />
                              <Route path='/attendance/undertime' element={<Undertime />} />
                              <Route path='/administration/allowed-devices' element={<AllowedDevice2 />} />
                          </>
                      )
                  } else if(localStorage.getItem('clientProfile') == "security_agency"){
                      return (
                          <>
                              <Route path='/attendance/overtime' element={<Overtime3 />} />
                              <Route path='/attendance/coa' element={<COA3 />} />
                              <Route path='/attendance/official-business' element={<OfficialBusiness3 />} />
                              <Route path='/employeeManagement/employee-list' element={<EmployeeList />} />
                              <Route path='/setup/employee/hours-shift' element={<HourShift />} />
                              <Route path='/payroll/generate-payroll/payroll-list/:id' element={<PayrollList3 />} />
                              <Route path='/leaves/leave-application' element={<LeaveApplication />} />
                              <Route path='/employeeManagement/edit-employee/:id' element={<EmployeeEdit />} />
                              <Route path='/employeeManagement/add-employee' element={<EmployeeAdd />} />
                              <Route path='/employeeManagement/show-employee/:id' element={<EmployeeShow />} />
                              <Route path='/setup/employee/work-site' element={<WorkSite />} />
                              <Route path='/leaves/holiday-management' element={<HolidayManagement />} />
                              <Route path='/leaves/leave-approval' element={<LeaveApproval />} />
                              <Route path='/attendance/undertime' element={<Undertime />} />
                              <Route path='/administration/allowed-devices' element={<AllowedDevice />} />
                          </>


                      )
                  } else if(localStorage.getItem('clientProfile') == "construction"){
                      return (
                          <>
                              <Route path='/attendance/overtime' element={<Overtime2 />} />
                              <Route path='/attendance/coa' element={<COA2 />} />
                              <Route path='/attendance/official-business' element={<OfficialBusiness2 />} />
                              <Route path='/employeeManagement/employee-list' element={<EmployeeList />} />
                              <Route path='/setup/employee/hours-shift' element={<HourShift />} />
                              <Route path='/payroll/generate-payroll/payroll-list/:id' element={<PayrollList />} />
                              <Route path='/leaves/leave-application' element={<LeaveApplication />} />
                              <Route path='/employeeManagement/edit-employee/:id' element={<EmployeeEdit />} />
                              <Route path='/employeeManagement/add-employee' element={<EmployeeAdd />} />
                              <Route path='/employeeManagement/show-employee/:id' element={<EmployeeShow />} />
                              <Route path='/setup/employee/work-site' element={<WorkSite2 />} />
                              <Route path='/leaves/holiday-management' element={<HolidayManagement />} />
                              <Route path='/leaves/leave-approval' element={<LeaveApproval />} />
                              <Route path='/attendance/undertime' element={<Undertime />} />
                              <Route path='/administration/allowed-devices' element={<AllowedDevice />} />
                          </>

                      )
                  }else if(localStorage.getItem('clientProfile') == "retail"){
                      return (
                          <>
                              <Route path='/attendance/overtime' element={<Overtime4 />} />
                              <Route path='/attendance/coa' element={<COA4 />} />
                              <Route path='/attendance/official-business' element={<OfficialBusiness4 />} />
                            <Route path='/employeeManagement/employee-list' element={<EmployeeList2 />} />
                              <Route path='/setup/employee/hours-shift' element={<HourShift2 />} />
                              <Route path='/payroll/generate-payroll/payroll-list/:id' element={<PayrollList2 />} />
                              <Route path='/leaves/leave-application' element={<LeaveApplication2 />} />
                              <Route path='/employeeManagement/edit-employee/:id' element={<EmployeeEdit2 />} />
                              <Route path='/employeeManagement/add-employee' element={<EmployeeAdd2 />} />
                              <Route path='/employeeManagement/show-employee/:id' element={<EmployeeShow2 />} />
                              <Route path='/setup/employee/work-site' element={<WorkSite2 />} />
                              <Route path='/leaves/holiday-management' element={<HolidayManagement2 />} />
                              <Route path='/leaves/leave-approval' element={<LeaveApproval2 />} />
                              <Route path='/attendance/undertime' element={<Undertime2 />} />
                              <Route path='/administration/allowed-devices' element={<AllowedDevice2 />} />
                        </>
                      )
                  }else if(localStorage.getItem('clientProfile') == "architecture"){
                      return (
                          <>
                              <Route path='/attendance/overtime' element={<Overtime5 />} />
                              <Route path='/attendance/coa' element={<COA5 />} />
                              <Route path='/attendance/official-business' element={<OfficialBusiness5 />} />
                              <Route path='/employeeManagement/employee-list' element={<EmployeeList2 />} />
                              <Route path='/setup/employee/hours-shift' element={<HourShift2 />} />
                              <Route path='/payroll/generate-payroll/payroll-list/:id' element={<PayrollList2 />} />
                              <Route path='/leaves/leave-application' element={<LeaveApplication3 />} />
                              <Route path='/employeeManagement/edit-employee/:id' element={<EmployeeEdit />} />
                              <Route path='/employeeManagement/add-employee' element={<EmployeeAdd />} />
                              <Route path='/employeeManagement/show-employee/:id' element={<EmployeeShow />} />
                              <Route path='/setup/employee/work-site' element={<WorkSite />} />
                              <Route path='/leaves/holiday-management' element={<HolidayManagement />} />
                              <Route path='/leaves/leave-approval' element={<LeaveApproval />} />
                              <Route path='/attendance/undertime' element={<Undertime />} />
                              <Route path='/administration/allowed-devices' element={<AllowedDevice2 />} />
                          </>
                      )
                  }else if(localStorage.getItem('clientProfile') == "standard"){
                      return (
                          <>
                              <Route path='/attendance/overtime' element={<Overtime4 />} />
                              <Route path='/attendance/coa' element={<COA4 />} />
                              <Route path='/attendance/official-business' element={<OfficialBusiness4 />} />
                              <Route path='/employeeManagement/employee-list' element={<EmployeeList2 />} />
                              <Route path='/setup/employee/hours-shift' element={<HourShift2 />} />
                              <Route path='/payroll/generate-payroll/payroll-list/:id' element={<PayrollListStandard />} />
                              <Route path='/leaves/leave-application' element={<LeaveApplication2 />} />
                              <Route path='/employeeManagement/edit-employee/:id' element={<EmployeeEdit2 />} />
                              <Route path='/employeeManagement/add-employee' element={<EmployeeAdd2 />} />
                              <Route path='/employeeManagement/show-employee/:id' element={<EmployeeShow2 />} />
                              <Route path='/setup/employee/work-site' element={<WorkSite2 />} />
                              <Route path='/leaves/holiday-management' element={<HolidayManagement2 />} />
                              <Route path='/leaves/leave-approval' element={<LeaveApproval2 />} />
                              <Route path='/attendance/undertime' element={<Undertime2 />} />
                              <Route path='/administration/allowed-devices' element={<AllowedDevice2 />} />
                          </>
                      )
                  }
              })()
          }



        <Route path='/payroll/allowance' element={<Allowance />} />
        <Route path='/payroll/deduction' element={<Deduction />} />
        <Route path='/payroll/setup/tax-rule-setup' element={<TaxRuleSetup />} />
        <Route path='/payroll/setup/payroll-range' element={<PayrollRange />} />
        <Route path='/payroll/setup/government-contributions' element={<GovernmentContribution />} />
        <Route path='/payroll/monthly-pay-grade' element={<MonthlyPayGrade />} />
        <Route path='/payroll/custom-pay-grade' element={<CustomPayGrade />} />
        <Route path='/payroll/paygrade' element={<MonthlyPayGrade />} />
        <Route path='/payroll/generate-payroll' element={<GeneratePayroll />} />

        <Route path='/payroll/adjustment' element={<Adjustment />} />
        <Route path='/payroll/report' element={<Report />} />
        <Route path='/payroll/setup/computation' element={<PayrollComputation />} />
        <Route path='/setup/general' element={<GeneralSetup />} />

        <Route path='/setup/payroll/late-computation' element={<LateComputation />} />

        <Route path='/scheduling' element={<Scheduling />} />
          <Route path='/setup/employee/shift-name' element={<ShiftName/>} />

        <Route path='/organizational-chart' element={<OrganizationChart />} />

        <Route path='/search-results' element={<GlobalSearch />} />
      {/*</Route>*/}

      {/*Error pages*/}
      <Route path='/unauthorized' element={<Notfound/>} />
    </Routes>
  )
}

export default AppRoutes
